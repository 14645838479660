<template>
  <div ref="container">
    <b-overlay :show="dataLoading" spinner-variant="primary" variants="white" opacity="0.6">
      <vs-table :data="results" stripe class="report-table mb-base mb-2">
        <template slot="thead">
          <vs-th v-for="(column, columnIndex) in columns" :key="columnIndex" class="text-left">
            <span @click="manageOrder(column)">
              {{ column.displayName }}
              <em
                v-if="isOrderByThisColumnActive(column.name)"
                class="vs-icon notranslate icon-scale th-sort-icon material-icons null"
              >{{ getArrowUpOrDown() }}</em>
            </span>
          </vs-th>
        </template>

        <template v-slot="{data}">
          <vs-tr v-for="(rowData, rowIndex) in data" :key="rowIndex">
            <vs-td
              v-for="(column, columnIndex) in columns"
              :key="columnIndex"
              :data="rowData[column.name]"
              :class="column.class"
            >
              {{ rowData[column.name] }}{{ column.unit ? column.unit : '' }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </b-overlay>
    <vs-row>
      <vs-col vs-w="2" class="text-left">
        <vs-button @click="getFile()">
          {{ download }}
        </vs-button>
      </vs-col>
      <vs-col vs-w="10" class="text-right">
        <vs-pagination v-model="page" :total="totalPages" :max="9" color="primary" />
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import store from '@/store'
import moment from 'moment'

export default {
  name: 'SearchPhrases',
  props: ['type', 'startDate', 'endDate', 'download'],
  data() {
    return {
      columns: [
        {
          displayName: this.$t('search.searchPhrases.phrase'),
          name: 'phrase',
          class: 'text-left',
        },
        {
          displayName: this.$t('search.searchPhrases.searchCount'),
          name: 'views',
          class: 'text-right',
        },
        {
          displayName: this.$t('search.searchPhrases.clicks'),
          name: 'clicks',
          class: 'text-right',
        },
        {
          displayName: this.$t('search.searchPhrases.ctr'),
          name: 'ctr',
          class: 'text-right',
          unit: '%',
        },
        {
          displayName: this.$t('search.searchPhrases.conversion'),
          name: 'sales_conversion',
          class: 'text-right',
          unit: '%',
        },
        {
          displayName: this.$t('search.searchPhrases.salesCount'),
          name: 'sales_count',
          class: 'text-right',
        },
        {
          displayName: this.$t('search.searchPhrases.salesUnitsSold'),
          name: 'sales_units_sold',
          class: 'text-right',
        },
        {
          displayName: this.$t('search.searchPhrases.salesTotal'),
          name: 'sales_total',
          class: 'text-right whitespace-no-wrap',
          unit: ' zł',
        },
      ],
      defaultSort: 'desc',
      page: 1,
      sort: this.defaultSort,
      orderBy: 'sales_total',
    }
  },
  computed: {
    dataLoading() {
      return store.state.searchPhraseReport.dataLoading
    },
    results() {
      return store.state.searchPhraseReport.results
    },
    totalPages() {
      return store.state.searchPhraseReport.totalPages
    },
  },
  watch: {
    startDate() {
      this.getData()
    },
    endDate() {
      this.getData()
    },
    type() {
      this.getData()
    },
    page() {
      this.getData()
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    isOrderByThisColumnActive(columnName) {
      return this.orderBy === columnName
    },
    getArrowUpOrDown() {
      return this.sort === 'asc' ? 'expand_less' : 'expand_more'
    },
    flipSortDirection() {
      if (this.sort === 'asc') {
        this.sort = 'desc'
      } else {
        this.sort = 'asc'
      }
    },
    setDefaultSortDirectionForColumn(column) {
      this.orderBy = column.name
      this.sort = this.defaultSort
    },
    manageOrder(column) {
      if (this.orderBy === column.name) {
        this.flipSortDirection()
      } else {
        this.setDefaultSortDirectionForColumn(column)
      }
      this.getData()
    },
    formatStartDate(date) {
      return moment(date).format('YYYY-MM-DD 00:00:00')
    },
    formatEndDate(date) {
      return moment(date).format('YYYY-MM-DD 23:59:59')
    },

    getFile() {
      const requestData = {
        type: this.type,
        start: this.formatStartDate(this.startDate),
        end: this.formatEndDate(this.endDate),
        sort: this.sort,
        orderBy: this.orderBy,
      }
      store.dispatch('searchPhraseReport/getFile', requestData)
    },
    getData() {
      const requestData = {
        type: this.type,
        start: this.formatStartDate(this.startDate),
        end: this.formatEndDate(this.endDate),
        page: this.page,
        sort: this.sort,
        orderBy: this.orderBy,
      }
      store.dispatch('searchPhraseReport/getData', requestData)
    },
  },
}
</script>

<style>
.vs-table--tbody {
  z-index: auto;
}

.vs-table--thead {
  z-index: auto;
}

.vs-pagination--ul {
  margin-bottom: 0;
}

.vs-pagination--li {
  width: auto;
  min-width: 35px;
}

.vs-pagination--li.is-current {
  padding-left: 3px;
  padding-right: 5px;
  min-width: 35px;
  border-radius: 35px;
}

.vs-pagination--li.is-current .effect {
  border-radius: 35px;
}
</style>
