<template lang="html">
  <b-card :title="$t('search.searchPhrases.header')">
    <SearchPhrases
      :start-date="start"
      :end-date="end"
      :type="type"
      :download="$t('search.searchPhrases.download')"
    />
  </b-card>
</template>

<script>
import SearchPhrases from '@/views/components/search/Report/SearchPhrases.vue'
import store from '@/store'

export default {
  components: { SearchPhrases },
  computed: {
    start() {
      return store.state.search.dateRange.start
    },
    end() {
      return store.state.search.dateRange.end
    },
    type() {
      if (store.state.search.activeDataSource === null) {
        return 'all'
      }
      return store.state.search.activeDataSource.value
    },
  },
}
</script>

<style>
.report-table table th:not(:first-child) .vs-table-text {
  text-align: right;
  display: block;
}
</style>
